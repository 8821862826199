import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fshared%2Fdesign-lib%2Fstyle-theme%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA43STW%2FTQBAG4Du%2F4pX2AhKtPPu9w8mO7b%2BBnH6kLW1tQgoGxH9Ha8RhZ3NIrFweeTQz75iP83zC73fA1dVncnGd5ueGoQY%2F7IbuU%2BHEUKnrqGtK1wzlKIRgSzcM1XjTm1S6ze4M6aF0x1Dj9ivdZ3f5KT3kOWN%2BSo8M1dve9qb0xFCd6UynS58YKphggvB93ks77aj0G4ainnoS%2BdzmefQY5fx3eR4%2FOun3DGWMtU7kdsj7%2BtGPou%2FDv3zcIPJ83Lz3rcjnafMuerHXl81TIjHP83b32NKu9JfszlvnS39lKGvbthc5zDmfMeqd%2BE4Whmptn1Jb%2BleG8mHXuFD6kWGaZS3xG8NqiSeGjRLfGOQlfmdQ9eaPc2%2BuDLISfzJ01f0XgyqkhtHALyu0XVY0OB7203tt9Mf%2F%2F%2BY6fBA1lGv0siJeXKJzSe6QN7iwxuQaWlZcPplliGuSOxc6eYavYqPAiNUpKTJSlTslxvR2moVOvAUpdH%2FucnTD0HW3W0aNd4x6h%2FuzBz0wTK0PjHqsR4atez0xtvo%2FfwEYop3XdQUAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fshared%2Fcomponents%2Flayout%2FHeader%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61W227bOBB9z1cQLRaIgVKgZMt26Jf9kwVFjWQ2EqmlqMTuIv%2B%2BECkq1MVu0tYPli2eGQ7PnBlOxF%2FUv8%2BcoP8eEMoYfy616mSOuaqUpiirOjg9IGRUQxGxvzSTrTBCSep%2BV8wAIlHSImAtYCGx6kyPfBW5OVMUE%2FJX%2F%2FcHFjKHi32xPT28PUTD1lENssOqAQn5jTBemH7E%2BPV4lNtDctlMrFsj%2BPPVGjbKR1aIC%2BQrsOgs8hykRY%2FRU0QQdmHeMljEGBh3sgUTmMYWkIu2qdiVIqkkBKuJO6LSOWicKWNUTdG2uaBWVSJ3fhumQVoOB5xmuehaio7NpX%2FLO932tDRKSAP6FG5XVGAxJWso2jv89641orhirqQBaSji4O0aludClhTFA9bAxWBWiVK%2Bw96Dj1qogBufqFuncPn6J06PF6YqvjnNwbiCwozHIst1LcrzBDDGsH3nP5RhoXSNoq1TYQiPRIu1MsyEmevRFLnXjzg%2BkhzKUFa7G0L8SuwnsRGfoQ%2BSol3sqKuZLoWkiHVGhQXQr46eU%2BvZEoyFgboNs7HIYv%2FEudDA3VG1eg13Tnc3Etw2jAPOwLwCyNOkNDRUzIiXkKM0akXdVGBDuymWEb7%2FSJUeQjoPdy28TiA0OX66FTx9wCK%2BhhbMWqxUk8skFrISEihKBpp9QuNkktLMulHZd%2BAGF6KnTEnDhAww3DUoX23O47h6v%2B%2Bt1pEvjSG0UTlZpfhzqJHEAdYEMCkG16fCysI5WJeknb%2FvNHO%2BDmk9X2y0akCba1CXM4QRtZAlLjo5qJp3meA4gx8C9COJ0vQbIhFxj%2F3BPuKnTZiB45Q%2BSllhQLt0et1%2B%2BXL6TVJ9Y8P7T5PMslZVnfk5yXTYxVJpm1d%2F2qT9FrQ1u%2FQHSMqgUBr%2BNEu%2Fyko%2FUnhmJ%2B1cNUsyVMO4MNf3hTsHjeb39KTb71LX61eV3m%2F2SbUmsUtB3H9v%2B3zEm5VoQol6XZHT%2Bn30RJYhLnXyM4WsBeZPuBJeII6lGmZjyY1xMEzcSsoWm8On7sLx0hs0YyecqUxj2Ny5DuEFZHW93QzHCTUNJiM7qSz2YZsQol1gK5jxqIU9qvOF%2Fax1rzAS4jBjULvAW7k%2BYv5dQy4YarkGkIjJHD3WQuJxEO9vnY01nYyqK1wP7O6GgfMtMAl0vGgjAS4ZEEEAT8OxV6bO0JCe1cu4wc0RLCYzu3Sw8DI5Hv1u87msf3MZWdntyCIGNvjymP0SwufU%2BdYeYOADmOIDmHKOGTvtKKkkHbldFZWXVUw8LhRWv9vb%2F2Q0w0MLDgAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var header = 'cvoqkc0';
export var headerBg = _7a468({defaultClassName:'cvoqkc6',variantClassNames:{type:{blue:'cvoqkc7',neutral:'cvoqkc8',orange:'cvoqkc9'}},defaultVariants:{},compoundVariants:[]});
export var headerCloudDesktop = 'cvoqkcg';
export var headerCloudMobile = 'cvoqkcf';
export var headerNavbar = 'cvoqkc1';
export var headerNavbarItem = 'cvoqkc2';
export var headerNavbarItemIcon = 'cvoqkc3';
export var headerNavbarSeparator = 'cvoqkc4';
export var headerTabs = 'cvoqkce';
export var headerTopBar = 'cvoqkc5';
export var headerTopBarHamburger = 'cvoqkcd';
export var headerTopBarHamburgerContainer = 'cvoqkcc';
export var headerTopBarLogo = 'cvoqkcb';
export var headerTopBarLogoContainer = 'cvoqkca';