import { FC } from "react"

import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import { MenuVariantProps } from "."

import { MenuItem } from "./Item"
import {
  menuDesktop,
  menuDesktopColumns,
  menuDesktopColumnsElement,
  menuDesktopContent,
  menuTitle,
} from "./styles.css"
import { Body } from "@casavo/habitat"

export const DesktopMenu: FC<MenuVariantProps> = ({ closeMenu, items }) => {
  return (
    <section className={menuDesktop}>
      <div className={menuDesktopContent}>
        <div className={menuDesktopColumns}>
          {items.map(({ elements, title }, index) => (
            <div key={index} className={menuDesktopColumnsElement}>
              <Body noMargin size="s">
                <span className={menuTitle}>{title}</span>
              </Body>
              {elements
                .filter(({ title }) => !!title)
                .map(({ event, url, ...rest }, i) => (
                  <a key={i} aria-label={title} href={url}>
                    <MenuItem
                      isDesktop
                      onClick={() => {
                        if (!!event) {
                          handleUserEventClick(event)
                        }
                        closeMenu()
                      }}
                      {...rest}
                    />
                  </a>
                ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
