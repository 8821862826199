import Link from "next/link"
import { FC } from "react"

import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import { MyImage } from "../../MyImage"

import { DesktopNavbar, HeaderNavbarProps } from "./DesktopNavbar"
import {
  headerTopBar,
  headerTopBarHamburger,
  headerTopBarHamburgerContainer,
  headerTopBarLogoContainer,
} from "./styles.css"

export type HeaderTopBarProps = HeaderNavbarProps & {
  showMortgageLogo: boolean
}

export const HeaderTopBar: FC<HeaderTopBarProps> = ({
  isMenuOpened,
  showMortgageLogo,
  toggleMenu,
  ...rest
}) => {
  const logoSource = showMortgageLogo ? "/images/logo/mortgage-it.webp" : "/images/logo/main.webp"
  return (
    <div className={headerTopBar}>
      <Link
        aria-label={"Casavo Logo"}
        className={headerTopBarLogoContainer}
        data-testid="casavo-logo"
        href={"/"}
        onClick={() => handleUserEventClick("CasavoLogo")}
      >
        <MyImage alt={"Casavo logo"} height={32} src={logoSource} width={160} />
      </Link>
      <div
        className={headerTopBarHamburgerContainer}
        onClick={() => {
          handleUserEventClick(isMenuOpened ? "Close" : "HamburgerMenu")
          toggleMenu()
        }}
      >
        <span className={`${headerTopBarHamburger} ${isMenuOpened ? "opened" : ""}`}></span>
      </div>
      <DesktopNavbar isMenuOpened={isMenuOpened} toggleMenu={toggleMenu} {...rest} />
    </div>
  )
}
