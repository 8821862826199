import { FC, MouseEventHandler } from "react"

import { buttonSizeVariants, buttonStyleVariants, buttonText } from "./style.css"
import { Body } from "@casavo/habitat"

type ButtonProps = {
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  size?: ButtonSize
  type?: "button" | "submit" | "reset"
  variant?: ButtonVariant
}
type ButtonSize = "small" | "medium" | "big"
type ButtonVariant = "primary" | "secondary" | "tertiary"

export const Button: FC<ButtonProps> = ({
  children,
  className,
  size = "big",
  variant = "primary",
  ...props
}) => {
  return (
    <button className={`${buttonStyleVariants[variant]} ${buttonSizeVariants[size]} ${className}`} {...props}>
      <div className={buttonText}>
        <Body noMargin size={typographyVariantFromButtonSize[size]}>
          {children}
        </Body>
      </div>
    </button>
  )
}

const typographyVariantFromButtonSize: Record<ButtonSize, "s" | "m" | "l"> = {
  big: "l",
  medium: "m",
  small: "m",
}
