import { FC, useEffect, useRef, useState } from "react"

import { Footer } from "./Footer"
import { Header } from "./Header"
import { SimpleHeader } from "./Header/SimpleHeader"
import { Menu } from "./Menu"
import { layoutContent, bottomSpace } from "./styles.css"

type LayoutProps = {
  accentColor?: Parameters<typeof Header>[0]["accentColor"]
  bottomSpacer?: boolean
  children: React.ReactNode
  hideFooter?: boolean
  hideMobileTabs?: boolean
  showMortgageLogo?: boolean
  simplifiedHeader?: boolean
  stickyHeader?: boolean
}

export const Layout: FC<LayoutProps> = ({
  accentColor,
  bottomSpacer = false,
  children,
  hideFooter = false,
  hideMobileTabs = false,
  showMortgageLogo = false,
  simplifiedHeader = false,
  stickyHeader = true,
}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const contentRef = useRef<HTMLElement>(null)

  const handleOutsideClick = ({ target }: MouseEvent) => {
    if (isMenuOpened && contentRef.current && contentRef.current.contains(target as HTMLElement)) {
      setIsMenuOpened(false)
    }
  }

  useEffect(() => {
    isMenuOpened ? document.body.classList.add("menu-opened") : document.body.classList.remove("menu-opened")
    document.addEventListener("mousedown", handleOutsideClick)
    return () => {
      document.body.classList.remove("menu-opened")
      document.removeEventListener("mousedown", handleOutsideClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpened])

  return (
    <>
      {!simplifiedHeader && (
        <Header
          accentColor={accentColor}
          hideMobileTabs={hideMobileTabs}
          isMenuOpened={isMenuOpened}
          isSticky={stickyHeader}
          showMortgageLogo={showMortgageLogo}
          toggleMenu={() => setIsMenuOpened(!isMenuOpened)}
        />
      )}
      {simplifiedHeader && (
        <SimpleHeader accentColor={accentColor} isSticky={stickyHeader} showMortgageLogo={showMortgageLogo} />
      )}
      {isMenuOpened && <Menu closeMenu={() => setIsMenuOpened(false)} />}
      <main
        ref={contentRef}
        className={`${layoutContent} ${stickyHeader ? "" : "not-sticky"} ${
          simplifiedHeader ? "simple-header" : ""
        }`}
      >
        {children}
      </main>
      {!hideFooter && <Footer />}
      {/* the spacer is required on the homepage when the sticky footer is showing (always when at the bottom) */}
      {bottomSpacer && <div className={bottomSpace}></div>}
    </>
  )
}
