import Link from "next/link"
import { useRouter } from "next/router"
import { FC } from "react"

import { CommonTabs } from "@app/shared/types/website-pages"
import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import { headerNavbarItem, headerTabs } from "./styles.css"
import { Body } from "@casavo/habitat"

type HeaderTabsProps = {
  tabs: CommonTabs
}

export const MobileNavbar: FC<HeaderTabsProps> = ({ tabs }) => {
  const { asPath } = useRouter()

  return (
    <div className={headerTabs}>
      {tabs.map(({ event, title, url }, index) => (
        <Link key={index} passHref aria-label={title} href={url}>
          <div
            className={`${headerNavbarItem} ${url === asPath ? "selected" : ""}`}
            onClick={() => handleUserEventClick(event)}
          >
            <Body noMargin size="m">
              {title}
            </Body>
          </div>
        </Link>
      ))}
    </div>
  )
}
