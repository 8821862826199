import { FC } from "react"

import { ChevronRight } from "../../icons/ChevronRight"
import { MyImage } from "../../MyImage"

import { menuItem } from "./styles.css"
import { Body } from "@casavo/habitat"

type MenuItemProps = {
  icon?: string
  isDesktop?: boolean
  onClick?: () => void
  showArrow?: boolean
  title: string
}

export const MenuItem: FC<MenuItemProps> = ({
  icon,
  isDesktop = false,
  onClick,
  showArrow = false,
  title,
}) => {
  return (
    <div className={`${menuItem} ${showArrow ? "with-arrow" : ""}`} onClick={onClick}>
      {!!icon && <MyImage alt={icon} height={40} src={`/images/menu/${icon}.webp`} width={40} />}
      <Body noMargin size={isDesktop ? "s" : "m"}>
        {title}
      </Body>
      {showArrow && <ChevronRight />}
    </div>
  )
}
