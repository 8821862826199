import useTranslation from "next-translate/useTranslation"
import { FC, useState } from "react"

import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import { Button } from "../../Button"
import { ChevronLeft } from "../../icons/ChevronLeft"

import { MenuVariantProps } from "."

import { MenuItem } from "./Item"
import { menuBack, menuMobile, menuTitle } from "./styles.css"
import { Body } from "@casavo/habitat"

export const MobileMenu: FC<MenuVariantProps> = ({ closeMenu, items }) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(NaN)
  const { t } = useTranslation()

  return (
    <section className={menuMobile}>
      {isNaN(selectedItemIndex) ? (
        items.map(({ event, title }, index) => (
          <MenuItem
            key={index}
            showArrow
            title={title}
            onClick={() => {
              handleUserEventClick(event)
              setSelectedItemIndex(index)
            }}
          />
        ))
      ) : (
        <>
          <Button
            className={menuBack}
            size="small"
            variant="tertiary"
            onClick={() => {
              handleUserEventClick("Back")
              setSelectedItemIndex(NaN)
            }}
          >
            <ChevronLeft />
            {t("common-new:back")}
          </Button>
          <Body noMargin>
            <span className={menuTitle}>{items[selectedItemIndex].title}</span>
          </Body>
          {items[selectedItemIndex].elements
            .filter(({ title }) => !!title)
            .map(({ event, url, ...rest }, index) => (
              <a key={index} aria-label={"menu link"} href={url}>
                <MenuItem
                  onClick={() => {
                    if (!!event) {
                      handleUserEventClick(event)
                    }
                    closeMenu()
                  }}
                  {...rest}
                />
              </a>
            ))}
        </>
      )}
    </section>
  )
}
