import Link from "next/link"
import { useRouter } from "next/router"
import useTranslation from "next-translate/useTranslation"
import { FC, useEffect, useState } from "react"

import { MyImage } from "@app/shared/components/MyImage"
import { CommonTabs } from "@app/shared/types/website-pages"
import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import { headerNavbar, headerNavbarItem, headerNavbarItemIcon, headerNavbarSeparator } from "./styles.css"
import { Body } from "@casavo/habitat"

export type HeaderNavbarProps = {
  isMenuOpened: boolean
  tabs: CommonTabs
  toggleMenu: () => void
}

export const DesktopNavbar: FC<HeaderNavbarProps> = ({ isMenuOpened, tabs, toggleMenu }) => {
  const { asPath } = useRouter()
  const { t } = useTranslation()
  const [selectedTabIndex, setSelectedTabIndex] = useState(NaN)

  useEffect(() => setSelectedTabIndex(tabs.findIndex(({ url }) => url === asPath)), [asPath, tabs])

  return (
    <div className={headerNavbar}>
      {tabs.map(({ event, title, url }, index) => (
        <Link key={index} passHref aria-label={title} href={url}>
          <div
            className={`${headerNavbarItem} ${!isMenuOpened && selectedTabIndex === index ? "selected" : ""}`}
            onClick={() => {
              if (isMenuOpened) {
                toggleMenu()
              }
              handleUserEventClick(event)
            }}
          >
            <Body noMargin>{title}</Body>
          </div>
        </Link>
      ))}
      <div className={headerNavbarSeparator}></div>
      <div
        className={`${headerNavbarItem} ${isMenuOpened ? "selected" : ""}`}
        onClick={() => {
          handleUserEventClick("Explore")
          toggleMenu()
        }}
      >
        <Body noMargin>{t("menu:hamburger")}</Body>
        <MyImage
          alt="menu chevron icon"
          className={`${headerNavbarItemIcon} ${isMenuOpened ? "is-rotated" : ""}`}
          height={20}
          src={`/images/icons/chevron-down.svg`}
          width={20}
        />
      </div>
    </div>
  )
}
